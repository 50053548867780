<template>
    <b-table
        :data="data"
        :paginated="true"
        per-page=10
        current-page.sync=1
        :pagination-simple="false"
        pagination-position="bottom"
        default-sort-direction="asc"
        :pagination-rounded="true"
        sort-icon="arrow-up"
        sort-icon-size="is_small"
        default-sort="id"
        aria-next-label="Próxima"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página atual"
        :page-input="false"
        pagination-order="is-centered"
        page-input-position=""
        debounce-page-input=""
        :striped="true"
        :hoverable="true"
        >
        <slot></slot>
    </b-table>
</template>

<script>
    export default { 
        props: {
            data: {
                type: Array,
                default: () => []
            },
            columns: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {}
        },
    }
</script>