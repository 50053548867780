<template>
  <div class="p-1">
  </div>
</template>

<script>
  import {mapActions} from 'vuex';

  export default{
    mounted() {
      this.logout();
    },

    methods: {
      ...mapActions('auth', ['ActionSignOut']),
      logout() {
        this.ActionSignOut().then(res => {
            this.$router.push('/')
        })
      }
    }
  }
</script>