<template>
  <div class="home">
    <navbar></navbar>
    <div class="columns">
      <div class="movida-sidebar aside">
        <nav-manage></nav-manage>
      </div>
      <div class="column main container">
        <div class="titulo pt-5 pb-3 mb-5">
          <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
            <div class="is-flex is-flex-direction-row is-align-items-center">
              <b-icon icon="user-shield" size="is-medium"> </b-icon>
              <h2 class="ml-3 is-size-3 has-text-weight-semibold">MFA</h2>
            </div>
          </div>
          <h3>
            Aumente a segurança da sua conta ativando a autenticação de dois fatores (2FA)
          </h3>
        </div>
        <div class="has-text-left">
          <section class="box" v-if="methods.length !== 0">
            <div class="columns">
              <div class="column">
                <b-field>
                  <div class="columns">
                    <div class="column is-2 method">
                      <h2 class="ml-3 is-size-4 has-text-weight-semibold"> Email </h2>
                    </div>
                    <div class="column">

                      <b-message type="is-primary">
                        Esse método encaminha o token de 2FA para o e-mail registado.
                      </b-message>

                      <div class="config">
                        <b-tag v-if="isMethodConfirmed(1) == true" rounded icon="check"
                          class="tag is-success align">
                          Habilitado
                        </b-tag>

                        <b-button v-else size="is-small" class="is-primary align" @click="register(1)">
                          Habilitar
                        </b-button>

                        <b-tag v-if="isMethodDefault(1) == true" rounded icon="check" class="tag is-success align">
                          Definido como padrão
                        </b-tag>

                        <b-button v-else size="is-small" class="align is-primary"
                          @click="setDefault(1)">
                          Definir como padrão
                        </b-button>
                    </div>
                  </div>
                  </div>
                </b-field>
                <b-field>
                  <div class="columns">
                    <div class="column is-2 method">
                      <h2 class="ml-3 is-size-4 has-text-weight-semibold"> Celular </h2>
                    </div>
                    <div class="column">

                      <b-message type="is-primary">
                        Esse método encaminha o token de 2FA para o celular registado.
                      </b-message>

                      <div class="config">
                        <b-tag v-if="isMethodConfirmed(2) == true" rounded icon="check" class="tag is-success align">
                          Habilitado
                        </b-tag>

                        <b-button v-else size="is-small" class="is-primary align" @click="register(2)">
                          Habilitar
                        </b-button>

                        <b-tag v-if="isMethodDefault(2) == true" rounded icon="check" class=" tag is-success align">
                          Definido como padrão
                        </b-tag>

                        <b-button v-else size="is-small" class="align is-primary" 
                          @click="setDefault(2)">
                          Definir como padrão
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-field>

                <b-field>
                  <div class="columns">
                    <div class="column is-2 method">
                        <h2 class="ml-3 is-size-4 has-text-weight-semibold"> App </h2>
                    </div>

                    <div class="column">
                      <b-message type="is-primary">
                        Esse método de token descartável baseada em tempo gerando tokens via aplicativo ex: Google
                        Authenticator
                      </b-message>

                      <b-tag v-if="isMethodConfirmed(3) == true" rounded icon="check" class="tag is-success align">
                        Habilitado
                      </b-tag>

                      <b-button v-else  size="is-small" class="is-primary align" @click="register(3)">
                        Habilitar
                      </b-button>

                      <b-tag v-if="isMethodDefault(3) == true" rounded icon="check" class="tag is-success align">
                        Definido como padrão
                      </b-tag>

                      <b-button v-else size="is-small" class="align is-primary" @click="setDefault(3)">
                        Definir como padrão
                      </b-button>
                    </div>
                  </div>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
  </div>
</template>

<style src="./MfaView.css">

</style>

<script>
import SidebarComponent from "@/components/SidebarComponent/SidebarComponent.vue"; // @ is an alias to /src
import NavbarComponent from "@/components/NavbarComponent/NavbarComponent.vue"; // @ is an alias to /src
import state from "@/modules/auth/store/state";
import { flash } from "@/mixins/flash";
import AppView from "./AppView.vue";
import SmsView from "./SmsView.vue";
import EmailView from "./EmailView.vue";
import _ from "lodash";

export default {
  components: {
    "nav-manage": SidebarComponent,
    navbar: NavbarComponent,
  },

  mixins: [flash],

  data: () => ({
    isLoading: false,
    isFullPage: true,
    selectApp: false,
    userId: state.user.id,
    mfa: "",
    methods: {},
  }),

  mounted() {
    this.show();
  },

  methods: {
    get user() {
      return state.user;
    },

    register(methodId) {
      this.mfa = methodId

      const data = {
        userId: state.user.id,
        methodId: this.mfa,
      };

      this.isLoading = true;

      return this.$http
        .post(process.env.VUE_APP_BFF_URL + "v2/movida/mfa/register", data, {
          headers: {
            Authorization: `Bearer ${$cookies.get("token_app")}`,
          },
        })
        .then((res) => {
          this.isLoading = false;

          if (this.mfa == 1) {
            this.$buefy.modal.open({
              parent: this,
              component: EmailView,
              hasModalCard: true,
              trapFocus: true,
            });
          }

          if (this.mfa == 2) {
            this.$buefy.modal.open({
              parent: this,
              component: SmsView,
              hasModalCard: true,
              trapFocus: true,
            });
          }

          if (this.mfa == 3) {
            this.$buefy.modal.open({
              parent: this,
              component: AppView,
              hasModalCard: true,
              trapFocus: true,
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.flashError(err, "is-bottom", 5000);
        });
    },

    setDefault(methodId) {
      const data = {
        userId: state.user.id,
        methodId: methodId,
      };

      return this.$http
        .post(process.env.VUE_APP_BFF_URL + "v2/movida/mfa/default", data, {
          headers: {
            Authorization: `Bearer ${$cookies.get("token_app")}`,
          },
        })
        .then((res) => {
          this.flashSuccess("Alterado com sucesso!", "is-bottom", 5000);
          document.location.reload(true);
        })
        .catch((err) => {
          this.isLoading = false;
          this.flashError(err, "is-bottom", 5000);
        });
    },

    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 10 * 1000);
    },

    show() {
      var data = {
        userId: state.user.id,
      };

      return this.$http
        .post(process.env.VUE_APP_BFF_URL + "v2/movida/mfa/show/", data, {
          headers: {
            Authorization: `Bearer ${$cookies.get("token_app")}`,
          },
        })
        .then((res) => {
          this.methods = res.data.data.methods;
        });
    },

    isMethodDefault(id) {
      var method = _.find(this.methods, { id: id, deviceDefault: 1 });
      return !_.isEmpty(method);
    },

    isMethodConfirmed(id) {
      var method = _.find(this.methods, { id: id, status: 1 });
      return !_.isEmpty(method);
    },
  },
};
</script>
