<template>
    <layout title="Perfil" subtitle="Dados do usuário" titleIcon="user">
        <div class="columns">
            <div class="column">
                <attribute title="Nome">
                    {{ user.nome }}
                </attribute>
            </div>
            <div class="column">
                <attribute title="Email">
                    <b-tag rounded class="showTag"
                        :class="user.confirmaEmail ? 'is-success' : 'notConfirmed'">{{
                            user.confirmaEmail ? 'Confirmado' :
                                'Não confirmado'
                        }}</b-tag>
                    {{ user.email }}
                </attribute>

            </div>
            <div class="column">
                <attribute title="Celular">
                    <b-tag rounded class="showTag"
                        :class="user.confirmaCelular ? 'is-success' : 'notConfirmed'">{{
                            user.confirmaCelular ?
                                'Confirmado' : 'Não confirmado'
                        }}</b-tag>
                    {{ user.celular }}
                </attribute>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <attribute title="Segundo fator de autenticação">
                    {{ user.statusMfa }}
                    <b-button class="mfa" size="is-small" type="is-primary" @click="mfa" label="Alterar" />
                </attribute>
            </div>
            <div class="column">
                <attribute title="Perfil">
                    {{ user.perfil }}
                </attribute>
            </div>
        </div>
    </layout>
</template>

<style src="./Show.css">

</style>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Attribute from '@/components/Layout/Attribute.vue';
import state from '@/modules/auth/store/state';

@Component({
    components: {
        Attribute
    },
})

export default class ProfileView extends Vue {
    data() {
        return {
            name: '',
            activeTab: 0,
            showBooks: false,
            isLoading: false,
            isFullPage: true
        }
    }

    get user() {
        return state.user;
    }

    mfa() {
        this.$router.push("/mfa").catch(() => { });
    }

    openLoading() {
        this.isLoading = true
        setTimeout(() => {
            this.isLoading = false
        }, 10 * 1000)
    }

}
</script>
