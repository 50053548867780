import validTokenMfa from './pages/ValidTokenMfaView.vue';

export default [
    {
        path: '/validTokenMfa',
        name: 'validTokenMfa',
        meta: {
            title: "ValidTokenMfa"
        },
        component: validTokenMfa
    },
];