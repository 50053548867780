<template>
  <div
    class="login is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
  >
    <div
      class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
    >
      <img alt="movida logo" src="@/assets/logo.svg" />
      <h1 class="is-size-2 has-text-weight-bold pb-5">{{ appTitle }}</h1>
    </div>
    <div class="card">
      <div class="card-content">
        <div class="content">
          <form v-if="!sendCode" @submit.prevent="submit()">
            <b-field
              class="has-text-left"
              label="Solicitar redefinição de senha"
            >
              <b-input
                v-model="username"
                placeholder="Insira um usuario Movida"
                maxlength="30"
              ></b-input>
            </b-field>

            <div class="buttons">
              <b-button
                class="is-fullwidth"
                label="Enviar e-mail"
                type="is-primary"
                native-type="submit"
                icon-left="check"
              />

              <b-button class="is-fullwidth" label="Voltar" @click="voltar" />
            </div>
          </form>

          <div v-else>
            <b-message type="is-success" has-icon>
              Link de redefinição de senha enviado com sucesso. Verifique seu
              e-mail.
            </b-message>
            <b-button class="is-fullwidth" label="Voltar" @click="voltar" />
          </div>
        </div>
        <b-loading
          :is-full-page="isFullPage"
          v-model="isLoading"
          :can-cancel="true"
        ></b-loading>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import "@/styles/LoginView.css";
import services from "@/http";
import { flash } from "@/mixins/flash";
import Footer from "@/components/Layout/FooterLogin.vue";

export default {
  components: {
    Footer,
  },
  mixins: [flash],
  data: () => ({
    isLoading: false,
    isFullPage: true,
    isRedirect: false,
    isAllowedOrigin: false,
    allowedOrigins: process.env.VUE_APP_ALLOWED_ORIGINS_REDIRECT,
    appTitle: process.env.VUE_APP_TITLE,
    sendCode: false,
    username: "",
  }),

  methods: {
    voltar() {
      this.$router.push("/").catch(() => {});
    },

    submit() {
      const appRequest = {
        grant_type: process.env.VUE_APP_GRANT_TYPE,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
      };

      return services.auth.app(appRequest).then((res) => {
        this.$store.dispatch("auth/ActionSetToken", {
          token: res.body.data.access_token,
          expiresIn: res.body.data.expires_in,
        });

        const request = {
          login: this.username,
        };

        this.isLoading = true;

        return this.$http
          .post(
            process.env.VUE_APP_BFF_URL + "v1/movida/user/code-reset-password",
            request,
            {
              headers: {
                Authorization: `Bearer ${$cookies.get("token_app")}`,
              },
            }
          )
          .then((res) => {
            this.sendCode = true;
          })
          .catch((err) => {
            this.flashError(err, "is-bottom", 5000);
          })
          .finally((res) => {
            this.isLoading = false;
          });
      });
    },
  },
};
</script>
