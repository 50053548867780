import { render, staticRenderFns } from "./FooterLogin.vue?vue&type=template&id=1e71d554"
import script from "./FooterLogin.vue?vue&type=script&lang=js"
export * from "./FooterLogin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../usr/local/share/.config/yarn/global/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports