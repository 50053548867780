<template>
    <div class="home">
        <navbar></navbar>
        <div class="columns">
            <div class="movida-sidebar aside">
                <sidebar></sidebar>
            </div>
            <div class="column main container">
                <div class="titulo pt-5 pb-3 mb-5">
                    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
                        <div>
                            <div class="is-flex is-flex-direction-row is-align-items-center mb-5">
                                <b-icon
                                    :icon=titleIcon
                                    size="is-medium">
                                </b-icon>
                                <h3 class="ml-3 is-size-3 has-text-weight-semibold">{{title}}</h3>
                            </div>
                            <h2 v-if="subtitle" class="subtitle">{{ subtitle }}</h2>
                        </div>
                    </div>
                </div>
                <div class="mb-5 is-flex is-justify-content-end">
                    <slot name="actions"></slot>
                </div>
                <section class="box">
                    <slot></slot>
                    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
                </section>
            </div>
        </div>
    </div>
</template>

<style src="./Layout.css"></style>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarComponent from '@/components/SidebarComponent/SidebarComponent.vue'; // @ is an alias to /src
import NavbarComponent from '@/components/NavbarComponent/NavbarComponent.vue'; // @ is an alias to /src

@Component({
    components: {
        "sidebar":SidebarComponent,
        "navbar":NavbarComponent
    },
})

export default class Layout extends Vue {
    @Prop({default: 'Título'}) title
    @Prop({default: 'profile'}) titleIcon
    @Prop({default: null}) subtitle
    @Prop({default: false}) isLoading

    data() {
        return {
        }
    }
}
</script>
