<template>
  <modal-crud title="Email" v-on:close="$emit('close')" :isLoading="isLoading">
    <section class="modal-card-body">
      <b-field label="Informe o código enviado para seu e-mail corportativo">
        <b-input
            type="text"
            maxlength="6"
            placeholder="Token"
            v-model="tokenEmail"
            required>
        </b-input>
      </b-field>
    </section>
    <div slot="buttons">
      <b-button
          label="Salvar"
          type="is-primary"
          @click="save()"/>
    </div>
  </modal-crud>
</template>

<style src="./AppView.css"></style>

<script>
import ModalCrud from '@/components/Layout/ModalCrud.vue';
import state from '@/modules/auth/store/state';
import { flash } from "@/mixins/flash";

export default {
  components: {
    "modal-crud": ModalCrud,
  },

  mixins: [flash],

  data: () => ({
    tokenEmail: null,
    isLoading: false,
    mixins: [flash],
  }),

  methods: {
    save() {
        var data = {
            userId: state.user.id,
            methodId: 1,
            token: this.tokenEmail
        }

        this.isLoading = true;

        return this.$http.post(process.env.VUE_APP_BFF_URL+'v2/movida/mfa/confirm', data, {
        headers : {
            Authorization: `Bearer ${$cookies.get("token_app")}`
        }
        })
        .then(res => {
            this.flashSuccess("Método confirmado com sucesso", "is-bottom", 5000)
            document.location.reload(true)  
        })
        .catch(err => {
            this.isLoading = false;
            this.flashError(err, "is-bottom", 5000)
        })
        .finally(()=>{
            this.isLoading = false;
            this.$emit('close')
        });
        
    }
  }
}
</script>
