import Login from './pages/login/LoginView.vue';
import Logout from './pages/login/Logout.vue';
import Forbidden from './pages/forbidden/ForbiddenView.vue';
import NotFound from './pages/not-found/NotFoundView.vue';
import Redirect from './pages/redirect/RedirectView.vue';
import ForgotPassword from './pages/forgot-password/ForgotPasswordView.vue';
import ResetPassword from './pages/reset-password/ResetPasswordView.vue';
import _ from 'lodash';

export default [
  {
    path: '/',
    name: 'login',
    meta: {
        title: "Login"
    },
    component: Login,
  },
  {
    name: "logout",
    path: "/logout",
    component: Logout,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
        title: "Esqueci a senha"
    },
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
        title: "Criar nova senha"
    },

    beforeEnter: (to, from, next) => {
      if (_.isEmpty(to.query.code)){
        next('/forgot-password');
      }
      next();
    },
    
    component: ResetPassword,
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    meta: {
      title: "Não autorizado"
    },
    component: Forbidden,
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound
  },
  {
      name: "jira",
      path: "/jira",
      meta: {
          title: "Jira"
      },
      component: Redirect,
  },
  {
    name: "splitc",
    path: "/splitc",
    meta: {
        title: "SplitC"
    },
    component: Redirect,
},
]