import { mapState } from 'vuex'

import MenuItens from '@/components/MenuItens/MenuItens.vue';

export default {
  components: {
    MenuItens
  },
  data() {
    return {
      nome: ''
    }
  },
  computed: {
    ...mapState({
      user(state) {
        return state.auth.user.nome;
      }
    })
  }
}


