import mfa from './pages/MfaView.vue'

export default [
    {
        path: '/mfa',
        name: 'mfa',
        meta: {
            title: "MFA",
        },
        component: mfa
    },
];