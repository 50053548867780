require('./plugins');

import Vue from 'vue'
import VueCookies from 'vue-cookies';
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import '@fortawesome/fontawesome-free/css/all.css'

// Layouts
import Layout from '@/components/Layout/Layout.vue';
import TableDefault from '@/components/Layout/TableDefault.vue';

Vue.component('layout', Layout);
Vue.component('table-default', TableDefault);

Vue.config.productionTip = false
Vue.use(VueCookies, { expires: '1d'})

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", process.env.VUE_APP_SENTRY_TARGET, /^\//],
      tracingOrigins: ["*"]
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')