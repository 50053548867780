<template>
  <div
    class="login is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
  >
    <div
      class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
    >
      <img alt="movida logo" src="@/assets/logo.svg" />
      <h1 class="is-size-2 has-text-weight-bold pb-5">{{ appTitle }}</h1>
    </div>
    <div class="card">
      <div class="card-content">
        <div class="content">
          <form v-if="!resetPass" @submit.prevent="submit()">
            <b-field class="has-text-left" label="Nova senha">
              <b-input
                v-model="password"
                placeholder="Nova senha"
                type="password"
                password-reveal
              ></b-input>
            </b-field>

            <b-field class="has-text-left" label="Confirme a nova senha">
              <b-input
                v-model="passwordConfirmation"
                placeholder="Confirme a nova senha"
                type="password"
                password-reveal
              ></b-input>
            </b-field>

            <div class="buttons">
              <b-button
                class="is-fullwidth"
                label="Alterar"
                type="is-primary"
                native-type="submit"
              />
            </div>
          </form>

          <div v-else>
            <b-message type="is-success" has-icon>
              Senha alterada com sucesso.
            </b-message>
            <b-button class="is-fullwidth" label="Voltar" @click="voltar" />
          </div>
        </div>
        <b-loading
          :is-full-page="isFullPage"
          v-model="isLoading"
          :can-cancel="true"
        ></b-loading>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import "@/styles/LoginView.css";
import services from "@/http";
import { flash } from "@/mixins/flash";
import Footer from "@/components/Layout/FooterLogin.vue";

export default {
  components: {
    Footer,
  },

  mixins: [flash],

  data: () => ({
    isLoading: false,
    isFullPage: true,
    isRedirect: false,
    appTitle: process.env.VUE_APP_TITLE,
    resetPass: false,
    password: "",
    passwordConfirmation: "",
  }),

  methods: {
    voltar() {
      this.$router.push("/").catch(() => {});
    },

    submit() {
      const appRequest = {
        grant_type: process.env.VUE_APP_GRANT_TYPE,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
      };

      return services.auth.app(appRequest).then((res) => {
        this.$store.dispatch("auth/ActionSetToken", {
          token: res.body.data.access_token,
          expiresIn: res.body.data.expires_in,
        });

        this.isLoading = true;

        const request = {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        };

        const params = new URL(location.href).searchParams;

        return this.$http
          .post(
            process.env.VUE_APP_BFF_URL +
              "v1/movida/user/reset-password?code=" +
              params.get("code"),
            request,
            {
              headers: {
                Authorization: `Bearer ${$cookies.get("token_app")}`,
              },
            }
          )
          .then((res) => {
            this.resetPass = true;
          })
          .catch((err) => {
            this.flashError(err, "is-bottom", 5000);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
  },
};
</script>
