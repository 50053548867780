import _ from 'lodash';
import store from '@/store';
import state from "@/modules/auth/store/state";

import {
    getCookieToken,
    setCookieToken,
    setBearerToken,
    deleteCookieToken,
    deleteCookieTokenApp,
    deleteCookieTokenUser,
    deleteCookieDomainToken
} from '@/modules/auth/storage';


const userAuth = {

    user: null,

    clear() {
        this.user = null
        deleteCookieToken()
    },

    /**
     * Desautentica o usuário atualmente autenticado na aplicação
     */
    logout() {
        this.clear()
    },

    /**
     * Verifica se usuário está autenticado e com seus dados disponíveis
     */
    isAuthenticated() {
        return this.hasUser() && this.hasTokenState()
    },

    hasUser(){
        return !this.isEmpty(store.getters['auth/user'])
    },

    hasTokenState(){
        return store.getters['auth/hasToken'];
    },

    hasTokenCookie() {
        return !this.isEmpty(this.getToken());
    },

    getToken() {
        return getCookieToken()
    },

    setToken(name, data) {
        setCookieToken(name, data)
    },
    setHeaderToken(data) {
        setBearerToken(data)
    },
    deleteToken() {
        deleteCookieToken()
        deleteCookieDomainToken()
    },
    getPermissions() {
        return store.getters['auth/scopes']
    },
    isEmpty(value) {
        return _.isEmpty(value);
    }
}

export default userAuth