import show from './pages/Show.vue';

export default [
    {
        path: '/profile',
        name: 'profile',
        meta: {
            title: "Perfil"
        },
        component: show
    },
];