import { routes as auth } from '../modules/auth';
import { routes as mfa } from '../modules/mfa';
import { routes as profile } from '../modules/profile';
import { routes as validTokenMfa} from '../modules/valid-token-mfa';

export default [
    ...auth,
    ...profile,
    ...validTokenMfa,
    ...mfa,
]