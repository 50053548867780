<template>
    <div class="img" v-html="svgContent"></div>
  </template>
  
  <script>
  export default {
    name: 'QrCodeView',
    props: {
      svgContent: {
        type: String,
        required: true
      }
    }
  }

</script>