export const redirectMixin = {
    methods: {
        redirectPage() {
            this.$store.dispatch('auth/ActionLoadSession');
            if (this.isRedirect) {
                this.redirectTo = this.params.get("redirect_to");
                const hostname = new URL(this.redirectTo).hostname;

                if (this.allowedOrigins.indexOf(hostname) > -1) {
                    this.isAllowedOrigin = true;
                } else {
                    this.flashError('Origem não permitida', "is-bottom", 5000);
                    return;
                }
            }

            if (this.isAllowedOrigin) {
                window.location.replace(`${this.redirectTo}`);
            } else {
                this.$router.push("/profile");
                this.flashSuccess("Login realizado com sucesso.", "is-bottom");
            }
        }
    }
}
