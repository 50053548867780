<template>
    <div
      class="
        login
        is-flex
        is-flex-direction-column
        is-justify-content-center
        is-align-items-center
      "
    >
      <div
        class="
          is-flex
          is-flex-direction-column
          is-align-items-center
          is-justify-content-center
        "
      >
        <img alt="movida logo" src="@/assets/logo.svg" />
        <h1 class="is-size-2 has-text-weight-bold pb-5">{{ appTitle }}</h1>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form v-if="!sendMFA" @submit.prevent="submit">
              <div class="field">
                <label style="font-size: 12px;" class="label" v-if="this.metodo_mfa === 1">Token enviado por E-mail</label>
                <label style="font-size: 12px;" class="label" v-else-if="this.metodo_mfa === 2 ">Token enviado via SMS</label>          
                <label style="font-size: 12px;" class="label" v-else-if="this.metodo_mfa  === 3">Utilizar Token gerado pelo App</label>
                <label class="label">Token MFA</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="form.mfatoken"
                    placeholder="Insira o token MFA"
                  />
                </div>
              </div>
              <div class="buttons">
                <b-button
                  class="is-fullwidth"
                  label="Acessar"
                  type="is-primary"
                  native-type="submit"
                  icon-left="check"
                />
              </div>
              <div v-if="showNewButton">
                <p class="is-size-6 has-text-centered my-2" v-if="outrosMetodosMFA && outrosMetodosMFA.length > 0">
                  Caso não receba o token pelo método padrão, solicite um novo token com os métodos abaixo
                </p>
                <p class="is-size-6 has-text-centered my-2" v-else>
                  O usuário não possui outros métodos MFA disponíveis
                </p>
                <div class="buttons is-flex is-flex-wrap-wrap is-justify-content-center" v-if="outrosMetodosMFA && outrosMetodosMFA.length > 0">
                  <b-button
                      v-for="method in outrosMetodosMFA"
                      :key="method.MfaMetodoID"
                      class="is-fullwidth"
                      :label="`Token enviado por ${method.Metodo}`"
                      type="is-primary"
                      @click="openModal(method.MfaMetodoID)"
                  />

                  <!-- Botão condicional para o método MfaMetodoID=3 se ele não for padrão e não existir na lista -->
                  <b-button
                      v-if="deveMostrarBotaoApp"
                      key="3"
                      class="is-fullwidth"
                      label="Token enviado por app"
                      type="is-primary"
                      @click="openModal(3)"
                  />
                </div>

              </div>
            </form>
            <div v-else>
            <b-message type="is-success" has-icon>
              Token validado com sucesso. 
            </b-message>
          </div>
          </div>
          <b-loading
            :is-full-page="isFullPage"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
        </div>
      </div>
      <Footer></Footer>
      <!-- Modal para enviar e validar novo token -->
      <b-modal v-model="showModal" :has-modal-card="true">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Token enviado por {{ getMetodoMFAName(metodo_mfa) }}</p>
            <button class="delete" aria-label="Fechar" @click="closeModal"></button>
          </header>
          <section class="modal-card-body">
            <label class="label">Novo Token MFA</label>
            <div class="control">
              <input class="input" type="text" v-model="novoToken" placeholder="Insira o novo token MFA" />
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button class="is-fullwidth" label="Enviar" type="is-primary" @click="enviarNovoToken" />
          </footer>
        </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { flash } from "@/mixins/flash";
  import { redirectMixin } from "@/mixins/redirectMixin";
  import Footer from "@/components/Layout/FooterLogin.vue";
  import state from "@/modules/auth/store/state";
  import AppValidateTokenView from "@/modules/valid-token-mfa/pages/AppValidateTokenView.vue";

  export default {
    components: {
      Footer
    },
    mixins: [flash,redirectMixin],
    data() {
      return {
        isLoading: false,
        isFullPage: true,
        isRedirect: state.redirect,
        isAllowedOrigin: false,
        redirectTo: state.redirect_to,
        allowedOrigins: process.env.VUE_APP_ALLOWED_ORIGINS_REDIRECT,
        appTitle: process.env.VUE_APP_TITLE,
        recaptcha_key: process.env.VUE_APP_KEY_RECAPTCHA,
        form: {
          mfatoken: null,
        },
        showNewButton: false,
        showTokenField: true,
        showModal: false,
        novoToken: "",
        sendMFA: false,
        metodo_mfa: state.mfa_padrao.MfaMetodoID,
        outrosMetodosMFA: state.outros_metodos_mfa,
        novoMetodoMFA: null,
        redirect: state.redirect,
        params: state.params,
      };
    },
    computed: {
      deveMostrarBotaoApp() {
        const appNaoEhPadrao = state.mfa_padrao.MfaMetodoID != 3;
        const appNaoExisteEmOutrosMetodos = !this.outrosMetodosMFA.some(method => method.MfaMetodoID == 3);

        return appNaoEhPadrao && appNaoExisteEmOutrosMetodos;
      }
    },
    mounted() {
      setTimeout(() => {
        this.showNewButton = true;
      }, 20000); // Tempo em milissegundos (30 segundos = 30000 milissegundos)
      this.isMethodApp(state.mfa_padrao.MfaMetodoID);
    },
    methods: {
      register(methodId) {
        this.novoMetodoMFA = methodId

        const data = {
          userId: state.user.id,
          methodId: this.novoMetodoMFA,
        };

        this.isLoading = true;

        return this.$http
            .post(process.env.VUE_APP_BFF_URL + "v2/movida/mfa/register", data, {
              headers: {
                Authorization: `Bearer ${$cookies.get("token_app")}`,
              },
            })
            .then((res) => {
              this.isLoading = false;
              if (this.novoMetodoMFA == 3) {
                this.$buefy.modal.open({
                  parent: this,
                  component: AppValidateTokenView,
                  hasModalCard: true,
                  trapFocus: true,
                });
              }
            })
            .catch((err) => {
              this.isLoading = false;
              this.flashError(err, "is-bottom", 5000);
            });
      },
      setDefault(methodId) {
        const data = {
          userId: state.user.id,
          methodId: methodId,
        };
        return this.$http
            .post(process.env.VUE_APP_BFF_URL + "v2/movida/mfa/default", data, {
              headers: {
                Authorization: `Bearer ${$cookies.get("token_app")}`,
              },
            })
            .then((res) => {
            })
            .catch((err) => {
              this.isLoading = false;
              this.flashError(err, "is-bottom", 5000);
            });
      },
      sendTokenMfa(metodo,token) {
        var decode = this.decodeToken(token);
        const mfa_request = {
          userId: decode.sub,
          Method: metodo,
        };
        this.isLoading = true;
        return this.$http
            .post(process.env.VUE_APP_BFF_URL + "v2/movida/mfa/token", mfa_request, {
              headers: {
                Authorization: `Bearer ${$cookies.get("token_app")}`,
              },
            })
            .then((res) => {
              this.isLoading = false;
              this.flashSuccess("Token enviado com sucesso", "is-bottom");
            })
            .catch((err) => {
              this.isLoading = false;
              this.flashError(err, "is-bottom", 5000);
            });
      },
      decodeToken(token) {
        try {
          const tokenParts = token.split('.');
          const payloadBase64 = tokenParts[1];
          const decodedPayload = this.decodeBase64Url(payloadBase64);

          const payload = JSON.parse(decodedPayload);

          return payload;
        } catch (error) {
          console.error('Erro ao decodificar o token:', error);
          return null;
        }
      },
      decodeBase64Url(base64Url) {
        const padding = '=='.slice(0, (4 - base64Url.length % 4) % 4);
        const base64 = (base64Url + padding).replace(/-/g, '+').replace(/_/g, '/');

        const decoded = window.atob(base64);

        return decoded;
      },
      enviarToken(metodoMFA) {
        if (this.isContemMFA(metodoMFA)) {
          if(metodoMFA != 3) {
            this.setDefault(metodoMFA);
            this.sendTokenMfa(metodoMFA,this.$cookies.get("token_user"));
          }
        }
        if(!this.isContemMFA(metodoMFA)){
          this.register(metodoMFA);
        }
      },
      isMethodApp(metodoMfa) {
        if(metodoMfa != 3) {
          this.sendTokenMfa(metodoMfa,this.$cookies.get("token_user"));
        }
      },
      submit() {
        this.isLoading = true;

        var decode = this.decodeToken(this.$cookies.get("token_user"))

        var checkMfa = this.isContemMFA(state.mfa_padrao.MfaMetodoID);

        if (decode.sub == state.user.id && checkMfa) {
          this.validate(state.user.id, this.metodo_mfa, this.form)
              .then(() => {
                this.sendMFA = true;
                this.isLoading = false;
                this.flashSuccess("Token MFA validado com sucesso.", "is-bottom");
                if(state.mfa_validate){
                  this.redirectPage();
                }
              })
              .catch((err) => {
                this.isLoading = false;
                this.flashError(err, "is-bottom", 5000);
              });
        } else {
          this.flashError('Usuário não corresponde com o usuário do Token', "is-bottom", 5000);

          return;
        }
      },
      isContemMFA(metodoMfa) {
        if (metodoMfa == state.mfa_padrao.MfaMetodoID) {
          return true;
        } else if (metodoMfa) {
          const encontrou = Object.values(state.outros_metodos_mfa).some((metodo) => metodo.MfaMetodoID == metodoMfa);
          return encontrou;
        } else {
          return false;
        }
      },
      validate(id, metodo, payload) {
        const request = {
          userId: id,
          methodId: metodo,
          token: payload.mfatoken
        };
        return this.$http
            .post(
                process.env.VUE_APP_BFF_URL + "v2/movida/mfa/token-validate", request,
                {
                  headers: {
                    Authorization: `Bearer ${this.$cookies.get("token_app")}`,
                  },
                }
            )
            .then((res) => {
              this.isLoading = false;
              this.$store.dispatch("auth/ActionLoadSession");
              if (res.body.status) {
                this.$store.dispatch("auth/ActionSetValidateMFA", {
                  mfa_validate: res.body.status,
                });
                if (metodo != state.mfa_padrao.MfaMetodoID) {
                  this.setDefault(metodo);
                }
              };
            })
            .catch((err) => {
              throw err.response.data.message;
            });
      },
      openModal(metodoMFA) {
        this.novoMetodoMFA = metodoMFA;
        this.enviarToken(metodoMFA);
        this.metodo_mfa = metodoMFA;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      getMetodoMFAName(metodoMFA) {
        const metodo = this.outrosMetodosMFA.find((method) => method.MfaMetodoID === metodoMFA);
        return metodo ? metodo.Metodo : "";
      },

      enviarNovoToken() {
        this.metodo_mfa = this.novoMetodoMFA;
        this.form.mfatoken = this.novoToken;

        this.submit();
        this.showModal = false;
        this.novoToken = "";
        this.showTokenField = true;
      }

    }

};
</script>