export const setBearerToken = (data) => $cookies.set("token", data.token,`${data.expiresIn}s`);
export const setCookieToken = (name, data) => $cookies.set("token_"+name, data.token,`${data.expiresIn}s`, null, process.env.VUE_APP_DOMAIN_COOKIES, true);
export const getCookieToken = () => $cookies.get("token_app");
export const deleteCookieToken = () => {
    $cookies.remove("token_user");
    $cookies.remove("token_app");
    $cookies.remove("token")
}

export const deleteCookieDomainToken = () => {
    const domain = process.env.VUE_APP_DOMAIN_COOKIES;
    $cookies.remove("token_user", "/", domain);
    $cookies.remove("token_app", "/", domain);
    $cookies.remove("token", "/", domain);
}



export const getLocalCheckToken = () => localStorage.getItem('check-token');
export const setLocalCheckToken = value => localStorage.setItem('check-token', value);

export const setPermissions = permissions => localStorage.setItem('permissions', permissions);
export const getPermissions = () => localStorage.getItem('permissions');