<template>
  <modal-crud
    title="App Authenticator"
    v-on:close="$emit('close')"
    :isLoading=isLoading>
    <section class="modal-card-body">
      <b-field is-centered label="Escanear o QrCode com app de 2FA">
        <qr-code-view :svg-content="imgQrCode"></qr-code-view>
      </b-field>
      <b-field is-centered label="Informe o código para confirmação do dispositivo">
          <b-input
              maxlength="6"
              type="text"
              placeholder="Token"
              id="tokenApp"
              v-model="tokenApp"
              required>
          </b-input>
      </b-field>
    </section>
    <div slot="buttons">
        <b-button
            label="Salvar"
            type="is-primary"
            @click="save"/>
    </div>
  </modal-crud>
</template>

<style src="./AppValidateTokenView.css"></style>

<script>
import QrCodeView from "@/modules/mfa/pages/QrCodeView";
import ModalCrud from "@/components/Layout/ModalCrud.vue";
import { flash } from "@/mixins/flash";
import { redirectMixin } from "@/mixins/redirectMixin";
import state from "@/modules/auth/store/state";

export default {
  components: {
    "modal-crud": ModalCrud,
    "qr-code-view": QrCodeView
  },

  mixins: [flash,redirectMixin],
  data: () => ({
    tokenApp: null,
    isLoading: false,
    imgQrCode: "",
  }),

  mounted() {
    this.generateQrcode();
  },

  methods: {
    generateQrcode() {
      this.isLoading = true;

      const data = {
        userId: state.user.id,
        methodId: 3,
      };

      return this.$http
        .post(process.env.VUE_APP_BFF_URL + "v2/movida/mfa/register", data, {
          headers: {
            Authorization: `Bearer ${$cookies.get("token_app")}`,
          },
        })
        .then((res) => {
          this.imgQrCode = res.body.data.img_qrcode;
        })
        .catch((err) => {
          this.flashError(err, "is-bottom", 5000);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    save() {
      var data = {
        userId: state.user.id,
        methodId: 3,
        token: this.tokenApp,
      };

      this.isLoading = true;

      return this.$http
        .post(process.env.VUE_APP_BFF_URL + "v2/movida/mfa/confirm", data, {
          headers: {
            Authorization: `Bearer ${$cookies.get("token_app")}`,
          },
        })
        .then((res) => {
          this.flashSuccess("Método confirmado com sucesso", "is-bottom", 5000);
        })
        .catch((err) => {
          this.flashError(err, "is-bottom", 5000);
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit("close");
        });
    },
  },
};
</script>
