<template>
    <div class="footer">
        <Version></Version>
    </div>
</template>

<script>
import Version from '@/components/Layout/Version.vue';

export default {

  components: {
    Version
  },

  data: () => ({

  })
}
</script>
