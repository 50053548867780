<template>
  <div
    class="
      login
      is-flex
      is-flex-direction-column
      is-justify-content-center
      is-align-items-center
    "
  >
    <div
      class="
        is-flex
        is-flex-direction-column
        is-align-items-center
        is-justify-content-center
      "
    >
      <img alt="movida logo" src="@/assets/logo.svg" />
      <h1 class="is-size-2 has-text-weight-bold pb-5">{{ appTitle }}</h1>
    </div>
    <div class="card">
      <div class="card-content">
        <div class="content">
          <form autocomplete="off" @submit.prevent="submit()">
            <b-field class="has-text-left" label="Usuário">
              <b-input
                v-model="form.username"
                autocomplete="nope"
                placeholder="Insira um usuario Movida"
                maxlength="30"
                ref="username">
              </b-input>
            </b-field>

            <b-field class="has-text-left pb-4" label="Senha">
              <b-input
                v-model="form.password"
                type="text"
                id="password"
                name="password"
                placeholder="Insira sua senha"
                autocomplete="off"
              ></b-input>
            </b-field>
            
            <b-field class="has-text-right pb-4">
              <a @click="forgotPass" class="forgot"> Esqueci a senha </a>
            </b-field>

            <div class="buttons">
              <b-button
                class="is-fullwidth"
                label="Entrar"
                type="is-primary"
                native-type="submit"
                icon-left="check"
              />

            </div>
          </form>
        </div>
        <b-loading
          :is-full-page="isFullPage"
          v-model="isLoading"
          :can-cancel="true"
        ></b-loading>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import "@/styles/LoginView.css";
import state from "@/modules/auth/store/state";
import services from '@/http';
import { flash } from "@/mixins/flash";
import { redirectMixin } from "@/mixins/redirectMixin";
import Footer from '@/components/Layout/FooterLogin.vue';
import jwt_decode from 'jwt-decode';

export default {
  components: {
    Footer
  },

  mixins: [flash,redirectMixin],
  data: () => ({
    isLoading: false,
    isFullPage: true,
    isRedirect: false,
    isSAML: false,
    isAllowedOrigin: false,
    redirectTo:"",
    allowedOrigins: process.env.VUE_APP_ALLOWED_ORIGINS_REDIRECT,
    appTitle: process.env.VUE_APP_TITLE,
    recaptcha_key: process.env.VUE_APP_KEY_RECAPTCHA,
    redirect: false,
    form: {
      username: "",
      password: "",
    },
    userId: state.user.id,
    params: {}
  }),
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      `https://www.google.com/recaptcha/enterprise.js?render=${this.recaptcha_key}`
    );
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    forgotPass(){
      this.$router.push("/forgot-password").catch(()=>{});
    },
    submit(){
      this.params = new URL(location.href).searchParams;

      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(this.recaptcha_key, {
          action: "login",
        });

        this.isRedirect = window.location.search.includes("redirect_to=");
        this.isSAML = window.location.search.includes("SAMLRequest="); 

        if (this.isSAML){
          this.form.SAMLRequest = this.params.get("SAMLRequest");
          this.form.RelayState = this.params.get("RelayState");
        }

        this.form.recaptcha = token;
        this.isLoading = true;        

        this.login(this.form)
          .catch((err) => {
            this.isLoading = false;    
            this.flashError(err, "is-bottom", 5000);
          });
      });
    },
        login(payload) {
          const appRequest = {
            grant_type: process.env.VUE_APP_GRANT_TYPE,
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET
          };

          return services.auth.app(appRequest).then(res => {
            this.$store.dispatch('auth/ActionSetToken', {
              token: res.body.data.access_token,
              expiresIn: res.body.data.expires_in,
            });

            const request = {
              login: payload.username,
              password: payload.password,
              recaptcha: payload.recaptcha
            };

            if (payload.SAMLRequest) {
              request.SAMLRequest = payload.SAMLRequest;
              request.RelayState = payload.RelayState;
            }

            const endpoint = payload.SAMLRequest ? 
              process.env.VUE_APP_BFF_URL + 'v1/movida/user/login' : 
              process.env.VUE_APP_BFF_URL + 'v1/movida/user/login_sec';

            return this.$http.post(endpoint, request, {
              headers: {
                Authorization: `Bearer ${$cookies.get("token_app")}`
              }
            }).then(res => {
              const params = new URL(location.href).searchParams;

              this.isLoading = false;

              if (res.body.data.SAMLResponse) {
                this.$store.dispatch('auth/ActionSetSAML', {
                  SAMLResponse: res.body.data.SAMLResponse,
                  RelayState: res.body.data.RelayState,
                });
                // SAML Login
                if (res.body.data.destination) {
                  this.postSAML(res.body.data.destination, {
                    SAMLResponse: res.body.data.SAMLResponse,
                    RelayState: res.body.data.RelayState
                  });
                } else {
                  this.flashError('URL de destino inexistente', "is-bottom", 5000);
                  return;
                }
              } else {
                // JWT Login
                const decodedToken = jwt_decode(res.body.data.token);

                this.$store.dispatch('auth/ActionSetTokenUser', {
                  token: res.body.data.token,
                  expiresIn: res.body.data.expires_in,
                  mfa_ativo: decodedToken.mfa_ativo,
                  mfa_padrao: decodedToken.mfa_padrao,
                  outros_metodos_mfa: decodedToken.outrosMetodosMfa,
                  confirmar_email: decodedToken.confirmar_email,
                  confirmar_celular: decodedToken.confirmar_celular,
                });

                this.$store.dispatch('auth/ActionLoadSession');
                this.$store.dispatch('auth/ActionSetRedirect', {
                  redirect: this.isRedirect,
                  redirect_to: this.redirectTo,
                  params: params
                });

                if (decodedToken.mfa_ativo && decodedToken.mfa_padrao.MfaMetodoID) {
                  this.$router.push({
                    path: '/validTokenMfa'
                  });
                }
              else {
                this.redirectPage();
              }
            }
          });
      });
    },
    postSAML(path, params, method='post') {
      // The rest of this code assumes you are not using a library.
      // It can be made less verbose if you use one.
      const form = document.createElement('form');
      form.method = method;
      form.action = path;

      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = params[key];

          form.appendChild(hiddenField);
        }
      }

      document.body.appendChild(form);
      form.submit();
    }
  },
};
</script>