<template>
    <div class="p-1">
        <b-menu>
            <b-menu-list label="Usuário">
                <b-menu-item tag="router-link" to="/profile" icon="user" label="Perfil"/>             
                <b-menu-item tag="router-link" to="/mfa" icon="user-shield" label="MFA"/>             
            </b-menu-list>
            <b-menu-list label="Ações">
                <b-menu-item icon="right-from-bracket" @click="logout" label="Logout"></b-menu-item>
            </b-menu-list>
        </b-menu>
    </div>
</template>

<style src="./MenuItens.css">
</style>

<script>
  export default{
    methods: {
      logout() {
        this.$router.push('/logout')
      }
    }
  }
</script>
