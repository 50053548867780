<template>
      <div class="version">
        <p>movidalabs - version 1.1.2</p>
      </div>
</template>

<script>
import { Vue } from 'vue-property-decorator';

export default class Version extends Vue {
    data() {
        return {
        }
    }
}
</script>
