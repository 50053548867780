export const SET_USER                   = 'AUTH/SET_USER';
export const SET_USER_SCOPES            = 'AUTH/SET_USER_SCOPES';
export const SET_TOKEN                  = 'AUTH/SET_TOKEN';
export const SET_MFA                    = 'AUTH/SET_MFA';
export const SET_MFA_METODO             = 'AUTH/SET_MFA_METODO';
export const SET_MFA_OUTROS_METODO      = 'AUTH/SET_MFA_OUTROS_METODO';
export const SET_CONFIMAR_EMAIL         = 'AUTH/SET_CONFIMAR_EMAIL';
export const SET_CONFIRMAR_CELULAR      = 'AUTH/SET_CONFIRMAR_CELULAR';
export const SET_MFA_VALIDATE           = 'AUTH/SET_MFA_VALIDATE';
export const DELETE_TOKEN               = 'AUTH/DELETE_TOKEN';
export const SET_REDIRECT               = 'AUTH/SET_REDIRECT';
export const SET_REDIRECT_TO            = 'AUTH/SET_REDIRECT_TO';
export const SET_PARAMS                 = 'AUTH/SET_PARAMS';
export const SET_RELAY_STATE_SPLITC     = 'AUTH/SET_RELAY_STATE_SPLITC';
export const SET_SAML_RESPONSE_SPLITC   = 'AUTH/SET_SAML_RESPONSE_SPLITC';
