<template>
  <div
    class="
      login
      is-flex
      is-flex-direction-column
      is-justify-content-center
      is-align-items-center
    "
  >
    <img alt="movida logo" src="@/assets/logo.svg" />
    <h1 class="is-size-2 has-text-weight-bold pb-5">Oops!</h1>

    <div class="card">
      <div class="card-content">
        <div class="content">
          <form @submit.prevent="submit()">

            <p class="title is-3 is-spaced has-text-centered">Desculpe, não localizamos o contéudo solicitado.</p>

            <div class="buttons">
              <b-button
                class="is-fullwidth"
                label="Voltar para o Home"
                type="is-primary"
                native-type="submit"
                icon-left="check"
              />
            </div>
          </form>
        </div>
        <b-loading
          :is-full-page="isFullPage"
          v-model="isLoading"
          :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/LoginView.css";
import { mapActions } from "vuex";

export default {
  data: () => ({
    isLoading: false,
    isFullPage: true,
  }),
  methods: {
    submit() {
      this.$router.push("/");
    },
  },
};
</script>