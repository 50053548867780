import { render, staticRenderFns } from "./SidebarComponent.vue?vue&type=template&id=33ad14ea"
import script from "./SidebarComponent.js?vue&type=script&lang=js&external"
export * from "./SidebarComponent.js?vue&type=script&lang=js&external"
import style0 from "./SidebarComponent.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../usr/local/share/.config/yarn/global/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports