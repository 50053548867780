<template>
    <div>
        <form action="">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{title}}</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <slot></slot>    
                <footer class="modal-card-foot">
                    <slot name="buttons"></slot>
                </footer>
            </div>
        </form>
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>

<script>
import "@/styles/Layout/modalCrud.css";

export default { 
    emits: ['close'],
    
    props: {
        title: "",
        isLoading: false
    },

    data() {
        return {}
    },

    mounted() {
    }
}
</script>